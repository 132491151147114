body {
  background-color: #fff;
}

.flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.text-align-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.dir-column {
  flex-direction: column;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-start {
  justify-content: start !important;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.align-start {
  align-items: start;
}

.gap {
  gap: 35px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-10 {
  gap: 10px !important;
}

.ant-layout-sider-zero-width-trigger {
  /* Sider collapse toggle trigger */
  top: 13px !important;
  z-index: 1;
}

.main-container {
  text-align: center;
  color: white;
}

.z-index {
  z-index: 1;
}

.credentials-container {
  border-radius: 25px;
  padding: 40px 70px 60px 70px;
}

.logo-container {
  margin: 20px;
}

.width-auto {
  width: auto !important;
}

.margin-left {
  margin-left: 5px;
}

.margin-not-top {
  margin: 0 18px 18px 18px !important;
}

.basic-margin {
  margin: 3rem 0rem 3rem 0rem;
}

.margin-top {
  margin-top: 1.5rem;
}

.font-20 {
  font-size: 20px !important;
}

.start-lab-header {
  margin: 20px 0;
}

.margin-bottom {
  margin-bottom: 1.5rem;
}

.margin-right {
  margin-right: 30px !important;
}

.label-bottom-m {
  margin-bottom: 15px !important;
}

.lab-margin {
  margin: 5px 0 5px 0;
}

.lab-disp-card-margin {
  margin: 35px;
}

.margin-mininum {
  margin: 10px;
}

.lab-disp-card-width {
  width: 350px;
}

.card-width-xs {
  width: 270px;
}

.popup-container {
  margin: 0.5rem 2.7rem 0 1rem !important;
}

.font-family-lucida {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

/* FORM */

.input-fields {
  height: 3.2rem;
  border-radius: 12px;
  font-size: larger;
}

.font-size-large {
  font-size: larger !important;
}

.lab-details>strong {
  font-size: x-large !important;
}

/* Antd Descriptions */

.mb-0 {
  margin-bottom: 0px !important;
}

.ant-descriptions-item-content {
  font-weight: 500 !important;
}

.ant-collapse-header {
  padding: 0px !important;
}

.ant-descriptions-item-label {
  background-color: rgb(230 247 255) !important;
}

.basic-input-width {
  /* input field and button static width */
  width: 350px;
}

.label-size-weight {
  font-size: medium;
  font-weight: 700;
}

.link {
  color: #6080ff;
}

.pointer {
  cursor: pointer;
}

.min-height {
  /* Min-Height For Cards display in lab repo in path='/' */
  min-height: 300px;
}

.border-none {
  border: none !important;
}

/* ADMIN FORMS */
.reg-btn-m {
  margin: 34px 0px 0px 0px;
}

.regformTittle {
  font-size: 35px;
  margin: 13px 13px 13px 5px;
}

.labelHeadings {
  color: #0b4a72;
  font-weight: 650;
}

/* Input Borders */
.ant-input,
.ant-select,
.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
  border: 1px solid #9cd8f4;
  outline: transparent;
  border-radius: 8px;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.box-shadow-effect {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
}

.ant-table-thead {
  font-family: Arial, Helvetica, sans-serif !important;
}

/* THEME COLORS */
/* BG */
.bg-skills-da {
  background-color: #0b4a72 !important;
}

.bg-hammer-dark {
  background-color: #262161 !important;
}

.bg-hammer-blue {
  background-color: #9cd8f4 !important;
}

.bg-motto-black {
  background-color: #000000 !important;
}

/* Font Colors */
.color-skills-da {
  color: #0b4a72;
}

.color-hammer-dark {
  color: #262161;
}

.color-hammer-blue {
  color: #6080ff;
}

.color-blue {
  color: #9cd8f4 !important;
}

.color-motto-black {
  color: #000000;
}

.easy-green {
  color: #36a606;
}

.medium-orange {
  color: #ffa303;
}

.hard-red {
  color: #ff2c2c;
}

.bg-darkblue-theme {
  background-color: #001529;
}

/* ANT DESIGN MODIFICATION */

.ant-card-head-title {
  font-weight: 650;
}

.ant-typography {
  font-size: 18px;
}

.ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.ant-modal-content {
  padding-bottom: 0 !important;
}

.ant-form-item-explain-error {
  margin: 10px;
}

.DraftEditor-editorContainer {
  height: 350px !important;
}

.ant-layout-sider-zero-width-trigger {
  /* Side nav trigger hidding */
  z-index: initial !important;
}

.spin-height {
  min-height: 760px;
}

.anticon-close-circle {
  /* input clear icon size */
  font-size: 14px !important;
}

/* Headers NAV BAR ANTD custom*/
.ant-tabs-tab-btn {
  font-size: 16px;
}

.tab-color {
  color: #fff !important;
}

/* ANTD table */
.ant-table-thead>tr>th {
  background-color: #f0f0f0 !important;
}

.ant-table-row-expand-icon-cell {
  background-color: #f0f0f0 !important;
}

.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: #f2f8ff !important;
}

.tabs-reg-button {
  margin-right: 16px;
}

.ant-table-content {
  overflow: visible !important;
}

.ant-row-rtl .tabs-reg-button {
  width: 150px;
  margin: 10px 60px 10px 15px;
}

.ant-tabs-nav-more {
  background-color: #36a606 !important;
}

.Radio-Group {
  box-shadow: 0 15px 15px 0 rgba(31, 38, 135, 0.37);
  height: 20px;
}

#startlab-table {
  box-shadow: 0 2px 10px 0 rgba(31, 38, 135, 0.37);
  border-radius: 5px;
}

#startlab-table>thead>tr>th {
  background-color: #0b4a72;
}

#startlab-table>thead>tr>th>span>strong {
  color: white;
}

.status-tags {
  font-size: medium;
  width: 110px;
}

/* Side Nav bar Admin Panel ANTD custom*/

.ant-layout-sider-trigger,
.menuFotter,
.adminDetails {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ant-menu-title-content,
svg>.sidebarIcon>.ant-menu-item-icon>path {
  /* Side Drawer custom color */
  color: #f1eee9;
  font-weight: bold;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-menu-inline-collapsed>.ant-menu-item,
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding-inline: calc(50% - 15px - 4px) !important;
}

.ant-layout-header-padding {
  padding-inline: 10px 20px !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

/* Sider border radius */
aside {
  border-radius: 0 15px 15px 0 !important;
  box-shadow: 10px 0px 10px 0 rgb(124 118 118 / 37%) !important;
}

.ant-upload-btn {
  /* upload btn height decresed by overide the padding*/
  padding: 8.4px 0px !important;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25) !important;
  /* Headers NAV BAR */
}

.ant-typography {
  margin-bottom: 1.8rem;
}

.menu-button {
  margin-top: 28px;
  padding-inline: 5px;
  border-radius: 0px 5px 5px 0px;
  color: antiquewhite;
}

.menu-btn-bg {
  background-color: #001529;
}

.homeLogo {
  width: 150px;
  margin: 10px 50px 10px 15px;
  cursor: pointer;
}

.menuLogo {
  width: 200px;
  display: flex;
  margin: 5px auto 25px;
}

.menuLogocollape {
  width: 35px;
  display: flex;
  margin: 0 auto 25px;
}

.menubarRightButtons,
.modelContents,
.deleteModalTitle,
.footerbuttons,
.startlabHeadertabs,
.startLabbutton {
  display: flex;
  gap: 10px;
}

.startlabHeader {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(11px);
  border-radius: 0 10px 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 3px 10px;
  min-height: 100vh;
}

.ant-table table {
  font-size: 18px;
}

.radioContents {
  font-size: 17px;
  padding: 12px;
}

.startLabbutton {
  justify-content: center;
  margin: 10px;
}

.startLabbuttonimg {
  cursor: pointer;
  width: 75%;
  background: #e1f3ff;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.backModel {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
}

.modelContents {
  align-items: center;
  justify-content: center;
}

.menu {
  background-color: #001529;
  color: #0b4a72;
  margin: -26px 0 30px -50px;
  width: 30vw;
  height: 100vh;
}

.ant-input-lg {
  font-size: large !important;
}

/* Side Nav bar Admin Panel */

.sidebarIcon {
  width: 25px;
  height: 25px;
}

.content {
  margin-top: 0 !important;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

.content-is-shown .content {
  transition: 0.25s;
  transition-timing-function: ease-out;
}

/* TEXT EDITOR STYLES*/

#root .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #f6f6f6;
  font-size: 18px !important;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

#root .ql-editor {
  min-height: 18em;
}

/*addlab label heading and buttons */
.labelHeadings {
  color: #0b4a72;
  font-weight: 650;
  font-size: medium !important;
  display: flex;
  align-items: center;
  gap: 35px;
}

.toggleButton {
  margin-top: 5px;
  background-color: transparent;
  color: #0b4a72;
  border: #0b4a72;
  font-size: 22px;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-inline-start: 0px;
}

/* Sider */
.SideBar {
  min-height: 92vh !important;
  max-height: max-content;
}

.sider-bg-color {
  background-color: #181d31 !important;
}

.ant-drawer-header {
  display: inline !important;
}

.ant-drawer-header-title {
  display: none !important;
}

.menuFotter {
  margin-bottom: 20px;
}

.deleteModal {
  border: 1px solid red;
}

.deletemodelFooter {
  display: flex;
  justify-content: flex-end;
  margin: 20px 10px 0;
}

.deleteModalTitle {
  align-items: center;
  color: #06293f;
  font-weight: bold;
  font-size: 20px;
}

.cardtableContents {
  text-align: left;
  line-height: 3;
}

.header-title-1 {
  /* font-family: Arial, Helvetica, sans-serif; */
  color: #0b4a72;
  font-size: 17px;
  line-height: 1.5;
  font-weight: 700;
}

.responsive {
  width: 100%;
  height: auto;
}

/* .ant-select-selector {
  height: 40px !important;
} */

.headerContainer {
  display: flex;
}

.header-title {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: #0b4a72;
  font-weight: 600;
  /* letter-spacing: 2px; */
  /* word-spacing: 3px; */
}

.sub-header-title {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #001529;
  font-weight: 600;
}

.header-color {
  color: white !important;
}

header .ant-tabs-tab {
  color: white !important;
}

.start-lab {
  background-image: url("../src/assets//img/start-lab-bg.jpg") !important;
}

.main-title {
  letter-spacing: 5px;
  font-weight: 600;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 4 !important;
}

.adminDetails {
  text-align: center;
  margin: 20px 50px;
}

.headerContainer {
  background-color: #f0f0f0;
  box-shadow: rgb(196 192 192 / 70%) 1px 4px 11px;
}

.content {
  margin: 20px;
}

.toggleButton {
  background: lightgray;
  height: 100px;
  border-radius: 0 12px 12px 0;
}

.studentlogin-formContainer {
  background: white;
  padding: 20px 40px;
  border-radius: 25px;
}

.verticalLine {
  border-left: 2px solid #f5f5f5;
  height: 70px;
  margin: 30px 30px;
}

.emptyline {
  height: 70px;
  margin: 30px 30px;
}

.ant-tour .ant-tour-inner .ant-tour-cover img {
  width: 85%;
}

@media screen and (max-width: 320px) {
  .credentials-container {
    padding: 0;
  }

  .basic-input-width {
    width: 200px;
  }

  .justify-space-between {
    flex-direction: column;
  }
}

@media screen and (min-width: 320px) {
  .credentials-container {
    padding: 20px;
  }

  .basic-input-width {
    width: 200px;
  }

  .justify-space-between {
    flex-direction: column;
  }
}

@media screen and (min-width: 375px) {
  .basic-input-width {
    width: 300px;
  }

  .justify-space-between {
    flex-direction: row;
  }
}

@media screen and (min-width: 500px) {
  .credentials-container {
    padding: 40px 70px 60px 70px;
  }

  .basic-input-width {
    width: 350px;
  }
}

@media screen and (max-width: 950px) {
  .homeLogo {
    margin: 15px 0;
  }

  .radioContents {
    font-size: 15px;
    padding: 5px;
    margin-left: -7%;
  }
}

@media screen and (max-width: 767px) {
  .reg-btn-m {
    margin: 15px 0px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .footerbuttons {
    display: grid;
    gap: 20px;
  }

  .title {
    font-size: 25px;
  }

  .startlabHeader {
    box-shadow: none;
    background-color: transparent;
  }
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

/* FOR START LAB LAB INSTRU IMAGES */
.article img {
  max-width: 750px;
  display: flex;
  margin: auto;
  word-wrap: break-word;
}

.article h5 {
  font-size: 25px;
}

.dashboardCardStyle {
  color: white;
  background-color: #0b4a72;
  border-radius: 50px;
  padding: 8px 0;
}

.stat-card:hover .dashboardCardStyle {
  height: 57px;
  width: 57px;
  transition: 1.2s;
  -webkit-transition: 1.2s;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.icon {
  font-size: 20px;
  color: rgb(69, 69, 124);
}

.iconName {
  font-size: 20px;
  color: black;
  margin-left: 3px;
}

.menuDisplay {
  background-color: aliceblue;
  color: #0b4a72;
}

.logo {
  width: 200px;
}

.activeComponent {
  background-color: #6080ff;
}

.footer {
  position: fixed;
  bottom: 20px;
}

@media screen and (max-width: 375px) {
  :where(.css-dev-only-do-not-override-17a39f8).ant-layout-header {
    padding-inline: 20px !important;
  }
}

:where(.css-dev-only-do-not-override-17a39f8).ant-pagination .ant-pagination-item-active {
  border-color: #0b4a72 !important;
  font-weight: 600 !important;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-pagination .ant-pagination-item-active a {
  color: #0b4a72 !important;
}

.sidenavbar {
  position: fixed;
}

@media screen and (max-width: 1190px) {
  .approveButton {
    margin-top: 10px;
  }
}

@media screen and (max-width: 990px) {
  .approveButton {
    margin-top: 0px;
  }
}

@media screen and (max-width: 370px) {

  :where(.css-dev-only-do-not-override-17a39f8).ant-descriptions.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-row>.ant-descriptions-item-label,
  :where(.css-dev-only-do-not-override-17a39f8).ant-descriptions.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-row>.ant-descriptions-item-content {
    padding: 12px 0px 12px 6px;
  }
}

:where(.css-dev-only-do-not-override-17a39f8).ant-descriptions .ant-descriptions-title {
  white-space: wrap !important;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  flex-wrap: nowrap;
}

@media screen and (max-width: 360px) {
  .expanable {
    overflow: scroll;
    width: 171px;
    display: flex;
  }
}

@media screen and (max-width: 400px) {
  .studentexpandable {
    overflow: scroll;
    width: 106px;
    display: flex;
  }
}

:where(.css-dev-only-do-not-override-17a39f8).ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0 !important;
  position: sticky;
  bottom: 0px;
  right: 20px;
  padding: 20px 0px;
  background-color: #f7f7f7;
}

@media screen and (max-width: 765px) {
  .labdisplayImage {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 765px) {
  .description-padding {
    padding: 0px 15px;
  }
}

@media screen and (max-width: 375px) {
  .padding-left {
    padding-left: 14px;
  }
}

.delete {
  background-color: red !important;
  border: 1px solid red !important;
  color: white !important;
}

.action-btn {
  background-color: rgb(0 21 41);
  border: 1px solid rgb(0 21 41);
  border-radius: 5px;
  padding: 11px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #9cd7f2;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.question {
  list-style-type: none;
  display: flex;
  gap: 30px;
  padding: 0;
}

.lab-content {
  font-size: 16px;
  /* margin-top: 10px; */
  text-align: justify;
}

:where(.css-dev-only-do-not-override-bdxy9o).ant-btn-dashed {
  background: #001529;
  border-color: transparent;
  color: #fff;

}

.border-left-design {
  border-left: 5px solid #0b4a72;
  padding-left: 10px;
  margin-top: 10px;
}


.launch-button {
  display: flex;
  /* align-items: center; */
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  gap: 5px;
  padding: 0.8em;
  color: white;
  /* background: #ad5389; */
  background: linear-gradient(190deg, #001529, #005d87, #001529);
  border: none;
  letter-spacing: 0.05em;
  border-radius: 8px;
}

.launch-button svg {
  margin-right: 3px;
  transform: rotate(30deg);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.launch-button span {
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.launch-button:hover svg {
  transform: translateX(5px) rotate(90deg);
}

.launch-button:hover span {
  transform: translateX(7px);
}

@media (min-width: 992px) and (max-width : 2500px) {

  .ant-drawer-open,
  .ant-drawer-content-wrapper {
    display: none;
  }
}